import * as React from 'react';
import '../../css/menuTopo_style.css';
import connect from 'react-redux/es/connect/connect';
import { NavLink, withRouter } from 'react-router-dom';
import { UserAvatar } from './UserAvatar';
import _ from 'lodash';
import UploadPictureRegister from '../../components/UploadPictureRegister';
import {
  constHelper,
  objectsConstants,
} from '../../constants/objects.constants';
import { Can } from '@casl/react';
import { userService } from '../../services/user.service';

let Core = require('../../js/sidePluguin');

type Props = {};
const options = {
  // duration od animations
  duration: 300,
  timer: 500,
  timerCurto: 300,
  timerLongo: 1000,
  // set small sidebar when window width < resizeWnd
  resizeWnd: 1000,
};

class Menu extends React.Component<Props, State> {
  componentDidMount() {
    let core = new Core(this.el, options, document.body, null, window);
    core.init();
  }
  getDataUrl = (entity) => {
    if (entity.foto && entity.foto.id == undefined && entity.foto.data) {
      return entity.foto.data;
    }
  };
  changeImage = (imageVO) => {
    console.log(imageVO);
    userService.updateFoto(imageVO.image).then((response) => {});
  };
  render() {
    const { permissions } = this.props;

    return (
      <div className="sidebar sidebar-hide-to-small sidebar-shrink sidebar-gestures">
        <div className="nano" ref={(el) => (this.el = el)}>
          <div className="nano-content">
            <div className="logo sidebar-toggle">
              <a href="#">
                <div className="d-lg-none iconeCollapse ">
                  <img src={require('../../img/arrow_menu.svg')} />
                </div>
                <img
                  src={require('../../img/icone.svg')}
                  data-expand={require('../../img/logo.svg')}
                  data-collapse={require('../../img/icone.svg')}
                  className="logoCustom"
                />
              </a>
            </div>
            <div className="perfil ">
              <div className="imgPerfil row justify-content-center">
                <div className=" text-center">
                  <UploadPictureRegister
                    name="foto"
                    hideLabel={true}
                    onChange={this.changeImage}
                    idImage={
                      this.props.user.foto ? this.props.user.foto.id : null
                    }
                    dataUrl={this.getDataUrl(this.props.user)}
                    id="foto"
                  />
                </div>
              </div>
              <div className="dadosPerfil row justify-content-center mt-2">
                <div className="text-center">
                  <h4 className="mb-0">{this.props.user.name}</h4>
                  <p className="cargo mt-n1">
                    {_.get(constHelper, this.props.user.role)}
                  </p>

                  <button
                    type="button"
                    className="btn btn-primary btn-sm"
                    onClick={() => {
                      this.props.history.push(
                        '/configuracoes/view/' + this.props.user.id
                      );
                    }}
                  >
                    Configurações
                  </button>
                </div>
              </div>
            </div>
            <ul className="menu">
              <li>
                <NavLink to="/home">
                  <i className="icon-inicial" /> Tela inicial
                </NavLink>
              </li>
              {/* <Can
                I={objectsConstants.VIEW}
                a={objectsConstants.ROLE_ADMIN}
                ability={permissions}
              >
                <li>
                  <NavLink to="/marca">
                    <i className="icon-os-1" /> Marca
                  </NavLink>
                </li>
              </Can>*/}


              <Can
                I={objectsConstants.VIEW}
                a={objectsConstants.ABILITY_ORDEM_SERVICO}
                ability={permissions}
              >
                <li>
                  <NavLink to="/ordemServico">
                    <i className="icon-os-1" /> Ordens de serviço
                  </NavLink>
                </li>
              </Can>

              <Can
                I={objectsConstants.VIEW}
                a={objectsConstants.ABILITY_COMPRA_PECA_VISUALIZACAO_POSTO}
                ability={permissions}
              >
                <li>
                  <NavLink to="/compraPeca">
                    <i className="icon-cp" /> Compra de Peças
                  </NavLink>
                </li>
              </Can>

              <Can
                I={objectsConstants.VIEW}
                a={objectsConstants.ABILITY_COMPRA_PECA}
                ability={permissions}
              >
                <li>
                  <NavLink to="/compraPecaAdmin">
                    <i className="icon-cp" /> Compra de Peças
                  </NavLink>
                </li>
              </Can>
              <li>
                <NavLink  to="/cadastro">
                  Cadastro de Posto Autorizado
                </NavLink>

              </li>
              <Can
                I={objectsConstants.VIEW}
                a={objectsConstants.ROLE_ADMIN}
                ability={permissions}
              >
                <li>
                  <NavLink to="/compraPecaAdmin">
                    <i className="icon-cp" /> Compra de Peças
                  </NavLink>
                </li>
              </Can>
              {/*        <Can
                I={objectsConstants.VIEW}
                a={objectsConstants.ROLE_ADMIN}
                ability={permissions}
              >
                 ordemServicoAdmin
                <li>
                  <NavLink to="/relogio">
                    <i className="icon-os-1" /> Relógio
                  </NavLink>
                </li>
              </Can>*/}
              <Can
                I={objectsConstants.VIEW}
                a={objectsConstants.ABILITY_DOC}
                ability={permissions}
              >
                <li>
                  <NavLink to="/doc">
                    <i className="icon-doc" /> DOC
                  </NavLink>
                </li>
              </Can>
              <Can
                  I={objectsConstants.VIEW}
                  a={objectsConstants.ABILITY_DOC_PECAS_IMPORTACAO}
                  ability={permissions}
              >
                <li>
                  <NavLink to="/doc/IMPORTACAO">
                    <i className="icon-doc" /> DOC Peças em importação
                  </NavLink>
                </li>
              </Can>
              <Can
                  I={objectsConstants.VIEW}
                  a={objectsConstants.ABILITY_DOC_PECAS_IMPORTACAO}
                  ability={permissions}
              >
                <li>
                  <NavLink to="/aguardandoImportacao">
                    <i className="icon-earth" /> Peças Aguardando Importação
                  </NavLink>
                </li>
              </Can>

              <Can
                I={objectsConstants.VIEW}
                a={objectsConstants.ABILITY_TROCA_MODELO}
                ability={permissions}
              >
                <li>
                  <NavLink to="/trocaModelo">
                    <i className="icon-icon_troca " /> Troca Modelo
                  </NavLink>
                </li>
              </Can>
              <Can
                I={objectsConstants.VIEW}
                a={objectsConstants.ABILITY_ESCOLHA_MODELO_TROCA}
                ability={permissions}
              >
                <li>
                  <NavLink to="/trocaModeloAdmin">
                    <i className="icon-icon_troca " /> Escolha do modelos para
                    troca
                  </NavLink>
                </li>
              </Can>
              <Can
                I={objectsConstants.VIEW}
                a={objectsConstants.ABILITY_RELATORIO_FINANCEIRO}
                ability={permissions}
              >
                <li>
                  <NavLink to="/financeiro">
                    <i className="icon-finance" /> Financeiro
                  </NavLink>
                </li>
              </Can>

              <Can
                I={objectsConstants.VIEW}
                a={objectsConstants.ABILITY_POSTO}
                ability={permissions}
              >
                <li>
                  <NavLink to="/postoAutorizadoList">
                    <i className="icon-posto" /> Postos autorizados
                  </NavLink>
                </li>
              </Can>
              <Can
                  I={objectsConstants.VIEW}
                  a={objectsConstants.ABILITY_GRUPO_FILIAL}
                  ability={permissions}
              >
                <li>
                  <NavLink to="/grupofilial">
                    <i className="sphere" /> Grupo Filial
                  </NavLink>
                </li>
              </Can>
              <Can
                I={objectsConstants.VIEW}
                a={objectsConstants.ABILITY_COLABORADORES}
                ability={permissions}
              >
                <li>
                  <NavLink to="/colaboradorcrud">
                    <i className="icon-colab" /> Colaboradores
                  </NavLink>
                </li>
              </Can>
              <Can
                I={objectsConstants.VIEW}
                a={objectsConstants.ABILITY_CADASTRO_DE_RELOGIO}
                ability={permissions}
              >
                <li>
                  <a
                    href="#"
                    data-toggle="collapse"
                    data-target="#submenuRelogio"
                    aria-expanded="false"
                  >
                    <i className="icon-relogio icon-relogio-menu" /> Relógio
                    <i className="iconSubMenu icon-seta_01  hideNano" />{' '}
                  </a>

                  <ul
                    className="subMenu nav collapse"
                    id="submenuRelogio"
                    role="menu"
                  >
                    <Can
                      I={objectsConstants.VIEW}
                      a={objectsConstants.ABILITY_CADASTRO_GRUPO_MARCAS}
                      ability={permissions}
                    >
                      <li>
                        <NavLink to="/grupoMarca">Grupos de marcas</NavLink>
                      </li>
                    </Can>
                    <Can
                      I={objectsConstants.VIEW}
                      a={objectsConstants.ABILITY_CADASTRO_MARCAS}
                      ability={permissions}
                    >
                      <li>
                        <NavLink to="/marca">Marcas</NavLink>
                      </li>
                    </Can>
                    <li>
                      <NavLink to="/relogio">Relógios</NavLink>
                    </li>
                    <li>
                      <NavLink to="/cadastroGarantia">Cadastro de Garantias</NavLink>
                    </li>
                  </ul>
                </li>
              </Can>

              <Can
                I={objectsConstants.VIEW}
                a={objectsConstants.ABILITY_IMPORTACAO}
                ability={permissions}
              >
                <li>
                  <a
                    href="#"
                    data-toggle="collapse"
                    data-target="#submenuImportacoes"
                    aria-expanded="false"
                  >
                    <i className="icon-import" /> Importações
                    <i className="iconSubMenu icon-seta_01  hideNano" />{' '}
                  </a>

                  <ul
                    className="subMenu nav collapse"
                    id="submenuImportacoes"
                    role="menu"
                  >
                    <li>
                      <NavLink to="/importacaoPecas">Importar peças</NavLink>
                    </li>
                    <li>
                      <NavLink to="/importacaoValoresPecas">
                        Importar valores de peças
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/importacaoPecasValoresEstoque">Importar Valores e Estoque</NavLink>
                    </li>
                    <li>
                      <NavLink to="/importacaoOrdemCompraClientePj">Importações Faturamento de relogio PJ</NavLink>
                    </li>

                  </ul>
                </li>
              </Can>

              <Can
                I={objectsConstants.VIEW}
                a={objectsConstants.ABILITY_CONFIGURAR_PERFIL}
                ability={permissions}
              >
                <li>
                  <NavLink to="/perfil">
                    <i className="icon-config" /> Configurar Perfil
                  </NavLink>
                </li>
              </Can>
              <Can
                  I={objectsConstants.VIEW}
                  a={objectsConstants.ABILITY_PAINEL_SITUACAO}
                  ability={permissions}
              >
                <li>
                  <NavLink to="/dashboard">
                    <i className="icon-pie-chart" /> Painel de situação
                  </NavLink>
                </li>
              </Can>
              <li>
                <a
                  href="#"
                  data-toggle="collapse"
                  data-target="#submenu"
                  aria-expanded="false"
                >
                  <i className="icon-relt-1" /> Relatórios
                  <i className="iconSubMenu icon-seta_01 hideNano" />{' '}
                </a>

                <ul className="subMenu nav collapse" id="submenu" role="menu">
                  <li>
                    <NavLink to="/relatorio/represamentoPreReparo">
                      1-Represamento de pré-reparo
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/relatorio/prazoMedioAtendimentoReparo">
                      2-Prazo médio de atendimento de reparo
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/relatorio/prazoMedioPosReparo">
                      3-Prazo médio de entrega pós reparo
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/relatorio/volumePorStatus">
                      4-Volumes por status
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/report/volumetroca">
                      5-Volume de trocas
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/report/consumoPecas">
                      6-Consumo de peças
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/report/consumoBaterias">
                      7-Relatório de baterias
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/report/analiseFalhas">
                      8-Análise de falhas
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/relatorio/prazoMedioAtendimentoTroca">
                      9-Prazo médio de atendimento de troca
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/report/analiseAtendimentoEpr">
                      10-Análise atendimento EPR
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/relatorio/disponibilidadePecasEPR">
                      11-Disponibilidade de peças EPR
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/report/pesquisaSatisfacao">
                      12-Pesquisa de satisfação
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/report/osExpirada">13-O.S. expirada</NavLink>
                  </li>
                  <li>
                    <NavLink to="/report/pecasNaoAtendidasEpr">
                      14– Peças não atendidas EPR
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/report/garantiaEstoque">
                      15- Garantia de estoque
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/report/osTipoPessoa">
                      16-Relatório PF/PJ
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/report/trocaBateriaAjustePulseira">
                      17-Troca de bateria e ajustes de pulseira
                    </NavLink>
                  </li>
                </ul>
              </li>

              {/* 
                  <NavLink to="/relatorio">
                    <i className="icon-relt" /> Relatórios
                  </NavLink> */}

              <li onClick={this.props.logout}>
                <a>
                  <i className="icon-logoff" /> Sair do sistema
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user, permissions } = state.authentication;

  return {
    user,
    permissions,
  };
}

const mapDispatch = ({ authentication: { logout } }) => ({
  logout: () => logout(),
});
const connectedMenu = withRouter(
  connect(
    mapStateToProps,
    mapDispatch
  )(Menu)
);
export { connectedMenu as Menu };
