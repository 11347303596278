import * as React from 'react';
import { Menu } from '../../components/menu/Menu';
import Topo from '../Topo/Topo';
import Home from '../home/Home';
import { Route, Switch } from 'react-router-dom';
import PostoAutorizado from '../postoAutorizado/PostoAutorizado';
import OrdemServico from '../ordemServico/OrdemServico';
import Cliente from '../cliente/Cliente';
import OrdemServicoAdminManaus from '../ordemServico/AdminManaus/OrdemServicoAdminManaus';
import Doc from '../Doc/Doc';
import Toast from '../../components/Toasts/Toast';
import Financeiro from '../financeiro/Financeiro';
import CompraPeca from '../compraPeca/CompraPeca';
import ImportacaoPecas from '../importacaoPecas/ImportacaoPecas';
import ImportacaoValoresPecas from '../importacaoValoresPecas/ImportacaoValoresPecas';
import CompraPecaAdminManaus from '../compraPeca/AdminManaus/CompraPecaAdminManaus';
import ColaboradorCrud from '../colaboradorCrud/ColaboradorCrud';
import Configuracoes from '../configuracoes/Configuracoes';
import Report from '../relatorios/Report';
import RelatorioRepresamentoPreReparo from '../relatorios/represamentoPreReparo/represamentoPreReparo';
import RelatorioPrazoMedioAtendimentoReparo from '../relatorios/prazoMedioAtendimentoReparo/prazoMedioAtendimentoReparo';
import RelatorioPrazoMedioEntregaPosReparo from '../relatorios/prozoMedioEntregaPosReparo/prazoMedioEntregaPosReparo';
import RelatorioVolumesPorStatus from '../relatorios/volumesPorStatus/volumesPorStatus';
import Conversa from '../chat/Conversa';
import PostoAutorizadoPageList from '../postoAutorizado/PostoAutorizadoPageList';
import RelatorioPrazoMedioAtendimentoTroca from '../relatorios/prazoMedioAtendimentoTroca/prazoMedioAtendimentoTroca';
import RelatorioDisponibilidadePecasEPR from '../relatorios/disponibilidadePecasEPR/disponibilidadePecasEPR';
import TrocaModelo from '../trocaModelo/TrocaModelo';
import TrocaModelosAdmin from '../trocaModelosAdmin/TrocaModelosAdmin';
import MarcaCrud from '../marca/MarcaCrud';
import Task from '../task/Task';
import ConfigurarPerfil from '../perfil/ConfigurarPerfil';
import GrupoMarca from '../grupoMarca/GrupoMarca';
import PostoHabilitadoEdit from '../postoHabilitado/PostoHabilitadoEdit';
import AlterarStatusOS from '../alterarStatusOS/AlerarStatusOS';
import ImportacaoPecasValoresListagem from "../importacaoPecasValores/ImportacaoPecasValoresListagem";
import {importacaoOrdemCompraClientePjService} from "../../services/importacaoOrdemCompraClientePjService";
import ImportacaoOrdemCompraClientePj from "../importacaoOrdemCompraClientePj/ImportacaoOrdemCompraClientePj";
import OsAguardandoImportacao from "../osAguardandoImportacao/OsAguardandoImportacao";
import Dashboard from "../dashboard/Dashboard";
import GrupoFilial from "../grupoFilial/GrupoFilial";
import RegisterMainForm from "../register/RegisterMainForm";
import TrocaBateriaAjustePulseira from "../relatorios/trocaBateriaAjustePulseira/TrocaBateriaAjustePulseira";
import GarantiaCrudTest from "../garantia/GarantiaCrud"
import RelogioCrud from '../relogio/RelogioCrud';

type Props = {};
type State = {
  isModalVisible: boolean,
};
export default class Main extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isModalVisible: false,
    };
    this._toggleModal = this._toggleModal.bind(this);
  }

  _toggleModal = () => {
    this.setState({ isModalVisible: !this.state.isModalVisible });
  };
  render() {
    return (
      <div>
        <Menu />
        <Topo />
        <Toast />
        <div className="content-wrap d-flex justify-content-center flex-column flex-lg-row ">
          <Switch>
            <Route path="/configuracoes" component={Configuracoes} />
            <Route path="/home" component={Home} />
            <Route path="/postoAutorizado" component={PostoAutorizado} />
            <Route
              path="/postoAutorizadoList"
              component={PostoAutorizadoPageList}
            />
            <Route path="/ordemServico" component={OrdemServico} />
            <Route path="/grupofilial" component={GrupoFilial} />
            <Route path="/compraPeca" component={CompraPeca} />
            <Route path="/compraPecaAdmin" component={CompraPecaAdminManaus} />

            <Route
              path="/postoHabilitado/novo/"
              component={PostoHabilitadoEdit}
            />
            <Route path="/cadastro" component={RegisterMainForm} />
            <Route
              path="/ordemServicoAdmin"
              component={OrdemServicoAdminManaus}
            />
            <Route path={'/trocaModelo'} component={TrocaModelo} />
            <Route path="/chat" component={Conversa} />
            <Route path="/cliente" component={Cliente} />
            <Route path="/marca" component={MarcaCrud} />
            <Route path="/financeiro" component={Financeiro} />
            <Route path="/relogio" component={RelogioCrud} />
            <Route path="/cadastroGarantia" component={GarantiaCrudTest} />

            <Route path="/doc" component={Doc} />
            <Route path="/colaboradorcrud" component={ColaboradorCrud} />
            <Route path="/importacaoPecas" component={ImportacaoPecas} />
            <Route path="/importacaoPecasValoresEstoque" component={ImportacaoPecasValoresListagem} />
            <Route path="/importacaoOrdemCompraClientePj" component={ImportacaoOrdemCompraClientePj} />

            <Route
              path="/importacaoValoresPecas"
              component={ImportacaoValoresPecas}
            />
            <Route path="/trocaModeloAdmin" component={TrocaModelosAdmin} />
            <Route path="/perfil" component={ConfigurarPerfil} />
            <Route path="/report" component={Report} />
            <Route path="/aguardandoImportacao" component={OsAguardandoImportacao} />

            <Route
              path="/relatorio/represamentoPreReparo"
              component={RelatorioRepresamentoPreReparo}
            />
            <Route
              path="/relatorio/prazoMedioAtendimentoReparo"
              component={RelatorioPrazoMedioAtendimentoReparo}
            />
            <Route
                path="/dashboard"
                component={Dashboard}
            />
            <Route
              path="/relatorio/prazoMedioPosReparo"
              component={RelatorioPrazoMedioEntregaPosReparo}
            />
            <Route
              path="/relatorio/volumePorStatus"
              component={RelatorioVolumesPorStatus}
            />
            <Route
              path="/relatorio/prazoMedioAtendimentoTroca"
              component={RelatorioPrazoMedioAtendimentoTroca}
            />
            <Route
                path="/report/trocaBateriaAjustePulseira"
                component={TrocaBateriaAjustePulseira}
            />
            <Route
              path="/relatorio/disponibilidadePecasEPR"
              component={RelatorioDisponibilidadePecasEPR}
            />

            <Route path="/task" component={Task} />
            <Route path="/GrupoMarca" component={GrupoMarca} />

            <Route path="/alterarstatusos" component={AlterarStatusOS} />
          </Switch>
        </div>
        <footer>
          <p>
            {new Date().getFullYear()} © Marph Sistemas. -{' '}
            <a href="https://www.seculus.com.br/" target="_blank">
              Seculus
            </a>
          </p>
        </footer>
      </div>
    );
  }
}
